<template>
  <div class="emb-register-wrap section-gap">
    <div class="container py-0">
      <v-layout row wrap align-center justify-center>
        <v-flex sm12 md12 lg8 xl7>
          <v-layout row mx-sm-0 mx-3 wrap align-center justify-center>
            <v-flex sm10 md5 lg6 xl6>
              <div class="emb-card sign-in-form form-margin d-block white pa-6">
                <h4>{{ $t("message.signinPage.title") }}</h4>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model.trim="signupForm.firstname"
                    type="text"
                    :placeholder="$t('message.signinPage.firstname')"
                    :rules="inputRules.basictextRules"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model.trim="signupForm.lastname"
                    type="text"
                    :placeholder="$t('message.signinPage.lastname')"
                    :rules="inputRules.basictextRules"
                  >
                  </v-text-field>

                  <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="signupForm.dob"
                        v-on="on"
                        :placeholder="$t('message.signinPage.dob')"
                        append-icon="event"
                        readonly
                        :rules="dobRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      :locale="selectedLocale.locale"
                      v-model="signupForm.dob"
                      :max="new Date().toISOString().substr(0, 10)"
                      :picker-date="pickerDate"
                      min="1910-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>

                  <v-text-field
                    v-model.trim="signupForm.email"
                    type="email"
                    :placeholder="$t('message.signinPage.email')"
                    :rules="emailRules"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model.trim="signupForm.phone"
                    type="tel"
                    :placeholder="$t('message.signinPage.phone')"
                    :rules="inputRules.basictextRules"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model.trim="signupForm.password"
                    type="password"
                    :placeholder="$t('message.signinPage.password')"
                    :rules="passwordRules"
                  >
                  </v-text-field>
                  <v-text-field
                    class="mb-4"
                    v-model.trim="signupForm.repassword"
                    type="password"
                    :placeholder="$t('message.signinPage.retypePassword')"
                    :rules="repasswordRules"
                  >
                  </v-text-field>

                  <v-select
                    :items="languages"
                    item-text="name"
                    item-value="locale"
                    :value="signupForm.language"
                    :label="$t('message.registerPage.language')"
                  ></v-select>
                  <!-- <v-select
                    :items="currencies"
                    item-text="title"
                    item-value="id"
                    :value="signupForm.currency"
                    :label="$t('message.registerPage.currency')"
                  ></v-select> -->
                  <div class="d-flex justify-start align-end">
                    <v-checkbox
                      color="accent"
                      v-model="acceptedConditions"
                      :label="$t('message.signinPage.acceptConditions')"
                    ></v-checkbox>
                    <p class="pl-2">
                      <router-link to="/term-condition" class="accent--text">
                        {{ $t("message.signinPage.seeConditions") }}
                      </router-link>

                      <!-- <v-dialog v-model="dialog" scrollable max-width="300px">
                        <v-card>
                          <v-card-title>{{
                            $t("message.signinPage.conditions")
                          }}</v-card-title>
                          <v-divider></v-divider>
                          <v-card-text style="height: 300px">
                            {{ conditions }}
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialog = false"
                            >
                              {{ $t("message.close") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog> -->
                    </p>
                  </div>
                  <v-checkbox
                    color="accent"
                    v-model="signupForm.newsletters"
                    :label="$t('message.signinPage.newletters')"
                  ></v-checkbox>
                  <v-checkbox
                    color="accent"
                    v-model="signupForm.sms"
                    :label="$t('message.signinPage.sms')"
                  ></v-checkbox>
                  <v-btn
                    class="accent mx-0 mb-4"
                    large
                    :disabled="!acceptedConditions || !showSubmit"
                    @click.stop.prevent="saveDetails"
                  >
                    <div v-if="showSubmit">
                      {{ $t("message.signinPage.signup") }}
                    </div>
                    <div v-else>
                      <v-progress-circular
                        indeterminate
                        color="grey"
                      ></v-progress-circular>
                    </div>
                  </v-btn>
                  <p>
                    {{ $t("message.signinPage.haveAccount")
                    }}<router-link to="/session/signin" class="accent--text">
                      {{ $t("message.signinPage.signin") }}</router-link
                    >
                  </p>
                </v-form>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
    <v-snackbar v-model="snackbar" color="red">
      {{ errorMsg(errorCode) }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      persistent
      transition="dialog-top-transition"
      max-width="500"
      v-model="dialog"
    >
      <template>
        <v-card>
          <v-card-text>
            <div class="pt-6">
              {{ $t("message.registerPage.emailConfirmation") }}
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="closeDialog()">{{ $t("message.close") }}</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
	
<script>
import { mapGetters } from "vuex";
import conditionsImport from "Assets/data/conditions";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signInWithCustomToken } from "firebase/auth";

export default {
  computed: {
    ...mapGetters(["selectedLocale", "languages", "currencies"]),
  },
  mounted() {
    this.signupForm.language = this.selectedLocale.locale;
  },
  watch: {
    menu(val) {
      val &&
        setTimeout(
          () => (
            (this.$refs.picker.activePicker = "YEAR"), (this.pickerDate = null)
          )
        );
    },
  },
  data() {
    return {
      pickerDate: "1995-1-1",
      snackbar: false,
      date: null,
      valid: false,
      errorCode: "",
      menu: false,
      emailRules: [
        (v) => !!v || this.$t("message.signinPage.error.emailRequired"),
        (v) =>
          /.+@.+/.test(v) ||
          this.$t("message.signinPage.error.auth/invalid-email"),
      ],
      passwordRules: [
        (v) => !!v || this.$t("message.signinPage.error.passwordRequired"),
        (v) =>
          v.length >= 6 ||
          this.$t("message.signinPage.error.auth/weak-password"),
        (v) =>
          (v && /^[A-Za-z\d@$!%*?&#()+=-_]{6,}$/.test(v)) ||
          this.$t("message.signinPage.error.auth/accepted-char"),
      ],
      repasswordRules: [
        (v) =>
          v == this.signupForm.password ||
          this.$t("message.signinPage.error.passwordDifferent"),
        (v) =>
          v.length >= 6 ||
          this.$t("message.signinPage.error.auth/weak-password"),
      ],
      dobRules: [
        (v) => !!v || this.$t("message.signinPage.error.dobRequired"),
        (v) =>
          this.validateOver18(v) ||
          this.$t("message.signinPage.error.dobMinor"),
      ],
      inputRules: {
        basictextRules: [
          (v) => !!v || this.$t("message.signinPage.error.fieldNotEmpty"),
        ],
      },
      acceptedConditions: false,
      signupForm: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        repassword: "",
        dob: "",
        phone: "",
        newsletters: true,
        language: "en",
        currency: 0,
        sms: true,
      },
      dialog: false,
      conditions: "",
      showSubmit: true,
    };
  },
  methods: {
    saveDetails() {
      this.showSubmit = false;
      this.$refs.form.validate();
      if (this.valid == true) {
        var new_user_info = {
          firstname: this.signupForm.firstname,
          lastname: this.signupForm.lastname,
          email: this.signupForm.email,
          password: this.signupForm.password,
          phone: this.signupForm.phone,
          dob: this.signupForm.dob,
          newsletters: this.signupForm.newsletters,
          language: this.signupForm.language,
          currency: this.signupForm.currency,
          sms: this.signupForm.sms,
        };

        const createUserFunc = httpsCallable(getFunctions(), "createUser");
        createUserFunc(new_user_info)
          .then((result) => {
            if (result.data.code == "OK") {
              signInWithCustomToken(getAuth(), result.data.token)
                .then(() => {
                  // redirect to home
                  this.$router.replace("/home");
                  return;
                })
                .catch(() => {
                  this.showSubmit = true;
                });
            } else if (result.data.code == "ERROR") {
              this.showSubmit = true;
              this.snackbar = true;
              if (result.data.error.errorInfo != undefined) {
                this.errorCode = result.data.error.errorInfo.code;
              } else {
                this.errorCode = "unknown";
              }
              console.log("Error!");
            }
          })
          .then(() => {
            this.showSubmit = true;
          });
      } else {
        this.showSubmit = true;
      }
    },
    calculateAge(birthday) {
      // birthday is a date
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    validateOver18(dob_text) {
      var dob = new Date(dob_text);
      return this.calculateAge(dob) > 18;
    },
    save(date) {
      // console.log("test: ", this.signupForm.dob);
      this.$refs.menu.save(date);
    },
    showConditions() {
      this.conditions = conditionsImport[this.selectedLocale.locale];
      this.dialog = true;
    },
    errorMsg(code) {
      if (code != "") {
        return this.$t("message.signinPage.error." + code);
      } else {
        return "";
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$router.push("/");
    },
  },
};
</script>